import { format } from "date-fns";
import { IUser } from '../models/IUser';

export const DatePicker_onFormatDate = (date?: Date): string => 
{
  if(date === undefined) return '';

  return format(date, 'dd-MM-yyyy');    
};

export const CreateAuthHeader = () : any => {
  // return authorization header with basic auth credentials
  let cached = sessionStorage.getItem('uhrevind_token') + "";
  let user : IUser = JSON.parse(cached) as IUser;

  if (user && user.token) {        
      return { 'Authorization': 'Bearer ' + user.token, 'Content-Type': 'application/json' };
  } else {
      return {};
  }
}

export const CreateAuthHeaderAsFormData = () : any => {
  // return authorization header with basic auth credentials
  let cached = sessionStorage.getItem('uhrevind_token') + "";
  let user : IUser = JSON.parse(cached) as IUser;

  if (user && user.token) {
      return { 'Authorization': 'Bearer ' + user.token, 'Content-Type': 'multipart/form-data' };
  } else {
      return {};
  }
}

export const RemoveLastNCharactersFromString = (str: string, n: number): string => {
  return str.substring(0, str.length - n);
}

export const FormatCurrency = (x: number, seperator?: string): string => {
  if(x == null || x == undefined || isNaN(x)) return "0,00";
  
  if (seperator != null) {
      //eslint-disable-next-line
      return x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".").replace(/\.([^\.]*)$/g, seperator + '$1');
  }

  return x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const SaveFileAs = async (window: any, filename: string, description: string, content: string, accept : any) => {
  if(accept === undefined)
  {
    accept = {'text/plain': ['.txt']};
  }    

  const handle = await window.showSaveFilePicker({
    suggestedName: filename,
    types: [{
        description: description,
        accept: accept
    }],
    });

    const blob = new Blob([content]);

    const writableStream = await handle.createWritable();
    await writableStream.write(blob);
    await writableStream.close();
}